.dialer {
	text-align: center;
	/* background-color: #fff; */
	box-sizing: content-box;
	padding: 0px;
	margin: 0px;
	height: 100vh;
	width: 100vw;
	padding: 0 1em;
	box-sizing: border-box;
}
.icon {
	padding-top: 10vh;
	width: 100%;
	display: grid;
}
.icon svg {
	width: 8em;
	margin: auto;
}
.form {
	margin-top: 2em;
}
.helper {
	font-family: "Manrope";
	font-style: normal;
	color: #212026;
	font-weight: 500;
}
.error {
	font-family: "Manrope";
	font-style: normal;
	color: red;
	font-weight: 500;
	margin: 0;
}
.inputs_container {
	display: grid;
	grid-gap: 0.4em;
	margin: 2em 0 0.8em;
}
.input_label {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 600;
	text-align: left;
	margin-left: 0.2em;
	color: #8c8c8c;
	line-height: 1.5;
	font-size: 0.9rem;
}
.input_field {
	font-family: "Manrope";
	font-style: normal;
	font-size: 1.2rem;
	font-weight: 600;
	line-height: 2.5;
	padding: 0 0.8em;
	border: none;
	background: #f2f2f2;
	border-radius: 3px;
}
.dial_controls {
	width: 100%;
}
.button {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
	line-height: 2.8em;
	text-transform: capitalize;
	color: #ffffff;
	background: #f48633;
	border-radius: 3px;
	border: none;
	margin: 0.8rem auto;
	width: 100%;
}
.background_texture {
	position: absolute;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	top: 0px;
	z-index: -1;
}
.texture_overlay {
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0) 0%,
		#ffffff 30.08%
	);
	position: absolute;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	top: 0px;
	z-index: 3;
}
.texture_graphic {
	z-index: 2;
	position: absolute;
	width: 100vw;
	top: 0px;
}

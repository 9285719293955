.call {
	text-align: center;
	background-color: #003c5b;
	box-sizing: content-box;
	padding: 0px;
	margin: 0px;
}
.call-screen {
	padding: 1vh 1px;
	display: grid;
	grid-template-rows: 80vh 20vh;
	box-sizing: border-box;
	height: 100vh;
	width: 100vw;
	color: #ffffff;
}
.call-info {
}
.call-type {
}
.call-type p {
	font-weight: 500;
	font-size: 1.125rem;
	/* font-size: 1.125rem; */
}
.contact {
}
.avatar {
	position: relative;
	height: 8rem;
	width: 8rem;
	display: grid;
	background: rgb(239 239 239 / 87%);
	border-radius: 7rem;
	margin: 1.5rem auto;
	background: linear-gradient(
		180deg,
		rgba(252, 252, 252, 0.2) 0%,
		rgb(252 252 252 / 0%) 100%
	);
}
.graphic {
	width: 7rem;
	transform: translate(-50%, -50%);
	top: 52%;
	left: 50%;
	position: absolute;
}

.contact p {
	font-size: 1.25rem;
	font-weight: 500;
}
.call-duration .label {
	font-size: 0.938rem;
	font-weight: 600;
	margin: 0.2em;
}
.call-duration .time {
	font-size: 2.188rem;
	font-weight: 700;
	margin: 0.2em;
}

.call-controls {
}
.flex-row-controls {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-row-gap: 1rem;
}

.call-control-button {
	height: 4rem;
	width: 4rem;
	padding-bottom: 5.5rem;
	background-color: transparent;
	border: transparent;
	color: #ffffff;
	margin: auto;
	position: relative;
	cursor: pointer;
}
.disabled {
	opacity: 0.1;
}
.call-control-button:active {
	opacity: 0.9;
}
.call-control-button:hover {
	opacity: 0.5;
}
.button_inner_wrap {
}
.call-control-button-label {
	font-size: 0.938rem;
	text-align: center;
	position: absolute;
	transform: translateX(-50%);
	left: 50%;
	width: max-content;
	padding-top: 0.4rem;
}

.container {
}
.popup {
	background: #ffffff;
	border-radius: 3px;
	padding: 1.5em 1rem;
	margin: 2px;
	position: absolute;
	bottom: 0.2vh;
}
.overlay {
	background-color: rgba(0, 0, 0, 0.38);
	height: 100vh;
	width: 100vw;
	position: absolute;
	z-index: -1;
	top: 0px;
	left: 0px;
}
.heading h4 {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 800;
	font-size: 18px;
	line-height: 25px;
	text-align: center;
	color: #212026;
	margin: 0.2em;
}
.summary p {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	text-align: center;
	color: #464646;
}
.controls button {
	font-family: "Manrope";
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
	line-height: 2.8em;
	text-transform: capitalize;
	color: #ffffff;
	background: #f48633;
	border-radius: 3px;
	width: 100%;
	border: none;
	margin: 1rem 0;
}

:root {
	font-size: 16px;
}
body {
	margin: 0;
	font-family: "Manrope", sans-serif;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
